<template>
  <div>
    <date-picker class="clh-datepicker" v-model="cDate" :type="type"></date-picker>
  </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: { DatePicker },
  props: {
    value: {
      type: [ Date, String ],
      default: () => ''
    },
    type: {
      type: String,
      default: 'datetime'
    }
  },
  computed: {
    cDate: {
      get() {
        return this.value
      },
      set(newVal) {
        return this.$emit('input', newVal)
      }
    }
  }
};
</script>
<style lang="scss">
.clh-datepicker {
  display: block !important;
  width: auto !important;
  .mx-input {
    box-shadow: unset !important;
    border: 1px solid #dee2e6 !important;
    min-height: 46px;
  }
}
</style>
